<template>
  <h4>Notificaciones de reserva</h4>
  <BlockUI :blocked="saving">
    <div class="spinner_container" v-if="saving">
      <ProgressSpinner />
    </div>
    <span
      class="fixed flex"
      style="right: 3em; gap: 0.5em; top: 8em; z-index: 10"
      v-if="isUnsaved && !saving"
    >
      <Button @click="cancel()" icon="pi pi-times" label="Cancel" />
      <Button @click="save()" icon="pi pi-check" label="Save" />
    </span>

    <Timeline
      :value="getBookingEvents"
      align="alternate"
      class="customized-timeline"
      v-if="customReminders && notificationOptions"
    >
      <template #marker="slotProps">
        <span
          :style="{
            backgroundColor: slotProps.item.customReminder
              ? '#7768ae'
              : slotProps.item.color,
          }"
          class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-2"
        >
          <i class="pi pi-bell" v-if="slotProps.item.customReminder"></i>
          <i :class="slotProps.item.icon" v-else></i>
        </span>
        <Button
          :style="{
            backgroundColor: slotProps.item.color,
          }"
          @click="addReminder"
          aria-label="Añadir recordatorio personalizado"
          class="add-reminder"
          v-if="slotProps.item.addReminder"
        >
        Añadir recordatorio
        </Button>
      </template>
      <template #content="slotProps">
        <Card v-if="slotProps.item.customReminder">
          <template #title> Recordatorio de reserva </template>
          <template #content>
            <img
              class="shadow-2 mb-3"
              :src="`${publicPath}images/booking/remind_booking.jpg`"
              width="200"
            />
            <p>
              Puedes crear tantos recordatorios de reserva como quieras y
              personalizar cuándo enviarlos y por qué vía.
            </p>
            <form>
              <div class="field-checkbox form-field">
                <InputNumber
                  :id="slotProps.item.key + '_hours_before'"
                  v-model="slotProps.item.hoursBefore"
                  :min="1"
                />
                <label
                  style="margin-right: 5px"
                  :for="slotProps.item.key + '_hours_before'"
                  >Número de horas antes de la reserva</label
                >
              </div>
              <div class="field-checkbox form-field">
                <Checkbox
                  :binary="true"
                  :id="slotProps.item.key + '_sms'"
                  v-model="slotProps.item.sms"
                />
                <label :for="slotProps.item.key + '_sms'"
                  ><i class="pi pi-user px-2"></i>Enviar SMS de recordatorio de
                  reserva</label
                >
              </div>
              <div class="field-checkbox form-field">
                <Checkbox
                  :binary="true"
                  :id="slotProps.item.key + '_email'"
                  v-model="slotProps.item.email"
                />
                <label :for="slotProps.item.key + '_email'"
                  ><i class="pi pi-user px-2"></i>Enviar email de recordatorio
                  de reserva</label
                >
              </div>
              <Button
                @click="removeReminder(slotProps.item.key)"
                class="p-button-danger"
                icon="pi pi-trash"
                label="Eliminar recordatorio"
              />
            </form>
          </template>
        </Card>
        <Card v-else-if="!slotProps.item.addReminder">
          <template #title v-if="slotProps.item.status">
            {{ slotProps.item.status }}
          </template>
          <template #content>
            <img
              :alt="slotProps.item.name"
              :src="publicPath + 'images/booking/' + slotProps.item.image"
              class="shadow-2 mb-3"
              v-if="slotProps.item.image"
              width="200"
            />
            <p class="text-lg">
              {{ slotProps.item.description }}
            </p>
            <form v-if="slotProps.item.form">
              <template
                :key="formInput.key"
                v-for="formInput of slotProps.item.form"
              >
                <div
                  :class="formInput.wrapper.classes"
                  v-if="formInput.component === 'Title'"
                >
                  {{ formInput.content }}
                </div>
                <div v-else :class="formInput.wrapper.classes">
                  <component
                    :id="formInput.key"
                    :is="formInput.component"
                    :name="formInput.key"
                    v-bind="formInput.props"
                    v-model="notificationOptions[formInput.model]"
                  />
                  <label :for="formInput.key"
                    ><i :class="`${formInput.icon} px-2`"></i
                    >{{ formInput.label }}</label
                  >
                </div>
              </template>
            </form>
          </template>
        </Card>
      </template>
    </Timeline>
  </BlockUI>
</template>

<script>
import * as _ from 'lodash';
import OrganizationService from '../../service/OrganizationService';
import { NOTIFICATION_KEYS } from '../../constants/constants';

const parseApiConfig = (notificationsConfig, remindersConfig) => {
  const findConfigByKey = (configKey) =>
    notificationsConfig.find(
      (config) =>
        config.configKey.type === configKey.type &&
        config.configKey.method === configKey.method
    );

  const notificationOptions = {
    createdSMSAuto: findConfigByKey(NOTIFICATION_KEYS.CREATE_SMS_AUTO).enabled,
    createdEmailAuto: findConfigByKey(NOTIFICATION_KEYS.CREATE_EMAIL_AUTO)
      .enabled,
    createdSMSManual: findConfigByKey(NOTIFICATION_KEYS.CREATE_SMS_MANUAL)
      .enabled,
    createdEmailManual: findConfigByKey(NOTIFICATION_KEYS.CREATE_EMAIL_MANUAL)
      .enabled,
    createdSMSAutoOrganization: findConfigByKey(
      NOTIFICATION_KEYS.CREATE_SMS_AUTO
    ).notifyOrganization,
    createdEmailAutoOrganization: findConfigByKey(
      NOTIFICATION_KEYS.CREATE_EMAIL_AUTO
    ).notifyOrganization,
    createdSMSManualOrganization: findConfigByKey(
      NOTIFICATION_KEYS.CREATE_SMS_MANUAL
    ).notifyOrganization,
    createdEmailManualOrganization: findConfigByKey(
      NOTIFICATION_KEYS.CREATE_EMAIL_MANUAL
    ).notifyOrganization,
    acceptedSMS: findConfigByKey(NOTIFICATION_KEYS.ACCEPT_SMS).enabled,
    acceptedEmail: findConfigByKey(NOTIFICATION_KEYS.ACCEPT_EMAIL).enabled,
    acceptedSMSOrganization: findConfigByKey(NOTIFICATION_KEYS.ACCEPT_SMS)
      .notifyOrganization,
    acceptedEmailOrganization: findConfigByKey(NOTIFICATION_KEYS.ACCEPT_EMAIL)
      .notifyOrganization,
    acceptedSMSManual: findConfigByKey(NOTIFICATION_KEYS.ACCEPT_SMS_MANUAL).enabled,
    acceptedEmailManual: findConfigByKey(NOTIFICATION_KEYS.ACCEPT_EMAIL_MANUAL).enabled,
    acceptedSMSOrganizationManual: findConfigByKey(NOTIFICATION_KEYS.ACCEPT_SMS_MANUAL)
      .notifyOrganization,
    acceptedEmailOrganizationManual: findConfigByKey(NOTIFICATION_KEYS.ACCEPT_EMAIL_MANUAL)
      .notifyOrganization,
    rejectedSMS: findConfigByKey(NOTIFICATION_KEYS.REJECT_SMS).enabled,
    rejectedEmail: findConfigByKey(NOTIFICATION_KEYS.REJECT_EMAIL).enabled,
    rejectedSMSOrganization: findConfigByKey(NOTIFICATION_KEYS.REJECT_SMS)
      .notifyOrganization,
    rejectedEmailOrganization: findConfigByKey(NOTIFICATION_KEYS.REJECT_EMAIL)
      .notifyOrganization,
    cancelSMS: findConfigByKey(NOTIFICATION_KEYS.CANCEL_SMS).enabled,
    cancelEmail: findConfigByKey(NOTIFICATION_KEYS.CANCEL_EMAIL).enabled,
    cancelSMSOrganization: findConfigByKey(NOTIFICATION_KEYS.CANCEL_SMS)
      .notifyOrganization,
    cancelEmailOrganization: findConfigByKey(NOTIFICATION_KEYS.CANCEL_EMAIL)
      .notifyOrganization,
  };

  const customReminders = remindersConfig.map((reminder, index) => ({
    key: `cr${index}`,
    customReminder: true,
    hoursBefore: reminder.hoursBefore,
    sms: reminder.smsEnabled,
    email: reminder.emailEnabled,
  }));

  return {
    notificationOptions,
    customReminders,
  };
};

const mapApiConfig = (notifications, reminders) => {
  const apiReminders = reminders.map((reminder) => ({
    hoursBefore: reminder.hoursBefore,
    smsEnabled: reminder.sms,
    emailEnabled: reminder.email,
  }));

  const apiNotifications = [
    {
      configKey: NOTIFICATION_KEYS.CREATE_SMS_MANUAL,
      enabled: notifications.createdSMSManual,
      notifyOrganization: notifications.createdSMSManualOrganization,
    },
    {
      configKey: NOTIFICATION_KEYS.CREATE_EMAIL_MANUAL,
      enabled: notifications.createdEmailManual,
      notifyOrganization: notifications.createdEmailManualOrganization,
    },
    {
      configKey: NOTIFICATION_KEYS.CREATE_SMS_AUTO,
      enabled: notifications.createdSMSAuto,
      notifyOrganization: notifications.createdSMSAutoOrganization,
    },
    {
      configKey: NOTIFICATION_KEYS.CREATE_EMAIL_AUTO,
      enabled: notifications.createdEmailAuto,
      notifyOrganization: notifications.createdEmailAutoOrganization,
    },
    {
      configKey: NOTIFICATION_KEYS.ACCEPT_SMS,
      enabled: notifications.acceptedSMS,
      notifyOrganization: notifications.acceptedSMSOrganization,
    },
    {
      configKey: NOTIFICATION_KEYS.ACCEPT_EMAIL,
      enabled: notifications.acceptedEmail,
      notifyOrganization: notifications.acceptedEmailOrganization,
    },
    {
      configKey: NOTIFICATION_KEYS.ACCEPT_SMS_MANUAL,
      enabled: notifications.acceptedSMSManual,
      notifyOrganization: notifications.acceptedSMSOrganizationManual,
    },
    {
      configKey: NOTIFICATION_KEYS.ACCEPT_EMAIL_MANUAL,
      enabled: notifications.acceptedEmailManual,
      notifyOrganization: notifications.acceptedEmailOrganizationManual,
    },
    {
      configKey: NOTIFICATION_KEYS.REJECT_SMS,
      enabled: notifications.rejectedSMS,
      notifyOrganization: notifications.rejectedSMSOrganization,
    },
    {
      configKey: NOTIFICATION_KEYS.REJECT_EMAIL,
      enabled: notifications.rejectedEmail,
      notifyOrganization: notifications.rejectedEmailOrganization,
    },
    {
      configKey: NOTIFICATION_KEYS.CANCEL_SMS,
      enabled: notifications.cancelSMS,
      notifyOrganization: notifications.cancelSMSOrganization,
    },
    {
      configKey: NOTIFICATION_KEYS.CANCEL_EMAIL,
      enabled: notifications.cancelEmail,
      notifyOrganization: notifications.cancelEmailOrganization,
    },
  ];

  return [apiNotifications, apiReminders];
};

export default {
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    this.organizationService
      .getOrganization(this.$route.params.id)
      .then((organization) => {
        const apiConfig = organization.config.notificationConfig;
        this.notificationConfigApiData = apiConfig;
        this.mapBooking(apiConfig, organization.config.reminders);
      });
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      saving: false,
      notificationConfigApiData: null,
      organizationService: null,
      originalCustomReminders: null,
      originalNotificationOptions: null,
      notificationOptions: null,
      customReminders: null,
      bookingEvents: [
        {
          status: 'Reserva creada',
          icon: 'pi pi-book',
          color: '#4d9de0',
          image: 'create_booking.jpeg',
          description:
            'Se ha creado una reserva. Controla las notificaciones de email y sms desde aquí.',
          form: [
            {
              component: 'Title',
              content: 'Reserva creada por el cliente',
              wrapper: {
                classes: ['text-lg', 'mb-2'],
              },
            },
            {
              component: 'Checkbox',
              key: 'created_sms',
              label: 'Enviar SMS de creación de reserva al cliente',
              model: 'createdSMSAuto',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'created_email',
              label: 'Enviar Email de creación de reserva al cliente',
              model: 'createdEmailAuto',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'created_sms_org',
              label: 'Enviar SMS de creación de reserva al punto de venta',
              model: 'createdSMSAutoOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'created_email_org',
              label: 'Enviar Email de creación de reserva al punto de venta',
              model: 'createdEmailAutoOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Title',
              content: 'Reserva creada por el punto de venta',
              wrapper: {
                classes: ['text-lg', 'mb-2'],
              },
            },
            {
              component: 'Checkbox',
              key: 'created_sms_manual',
              label: 'Enviar SMS de creación de reserva al cliente',
              model: 'createdSMSManual',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'created_email_manual',
              label: 'Enviar Email de creación de reserva al cliente',
              model: 'createdEmailManual',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'created_sms_org_manual',
              label: 'Enviar SMS de creación de reserva al punto de venta',
              model: 'createdSMSManualOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'created_email_org_manual',
              label: 'Enviar Email de creación de reserva al punto de venta',
              model: 'createdEmailManualOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
          ],
        },
        {
          status: 'Reserva aceptada',
          icon: 'pi pi-user-edit',
          color: '#e1bc29',
          image: 'answered_booking.jpg',
          description:
            'Tu punto de venta ha aceptado la solicitud de reserva. Controla las notificaciones de email y sms desde aquí.',
          form: [
             {
              component: 'Title',
              content: 'Reserva creada por el cliente',
              wrapper: {
                classes: ['text-lg', 'mb-2'],
              },
            },
            {
              component: 'Checkbox',
              key: 'accepted_sms',
              label: 'Enviar SMS de reserva aceptada al cliente',
              model: 'acceptedSMS',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'accepted_email',
              label: 'Enviar Email de reserva aceptada al cliente',
              model: 'acceptedEmail',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'accepted_sms_org',
              label: 'Enviar SMS de reserva aceptada al punto de venta',
              model: 'acceptedSMSOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'accepted_email_org',
              label: 'Enviar Email de reserva aceptada al punto de venta',
              model: 'acceptedEmailOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Title',
              content: 'Reserva creada por el punto de venta',
              wrapper: {
                classes: ['text-lg', 'mb-2'],
              },
            },
            {
              component: 'Checkbox',
              key: 'accepted_sms_manual',
              label: 'Enviar SMS de reserva aceptada al cliente',
              model: 'acceptedSMSManual',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'accepted_email_manual',
              label: 'Enviar Email de reserva aceptada al cliente',
              model: 'acceptedEmailManual',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'accepted_sms_org_manual',
              label: 'Enviar SMS de reserva aceptada al punto de venta',
              model: 'acceptedSMSOrganizationManual',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'accepted_email_org_manual',
              label: 'Enviar Email de reserva aceptada al punto de venta',
              model: 'acceptedEmailOrganizationManual',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            }
          ],
        },
        {
          status: 'Reserva rechazada',
          icon: 'pi pi-ban',
          color: 'IndianRed',
          image: 'answered_booking.jpg',
          description:
            'Tu punto de venta ha rechazado la solicitud de reserva. Controla las notificaciones de email y sms desde aquí.',
          form: [
            {
              component: 'Checkbox',
              key: 'rejected_sms',
              label: 'Enviar SMS de reserva rechazada al cliente',
              model: 'rejectedSMS',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'rejected_email',
              label: 'Enviar Email de reserva rechazada al cliente',
              model: 'rejectedEmail',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'rejected_sms_org',
              label: 'Enviar SMS de reserva rechazada al punto de venta',
              model: 'rejectedSMSOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'rejected_email_org',
              label: 'Enviar Email de reserva rechazada al punto de venta',
              model: 'rejectedEmailOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
          ],
        },
        {
          addReminder: true,
          status: 'Add reminder',
          icon: 'pi pi-bell',
          color: '#7768ae',
        },
        {
          status: 'Reserva cancelada',
          icon: 'pi pi-times',
          color: '#e15554',
          image: 'cancel_booking.jpg',
          description: 'El cliente o el punto de venta cancela la reserva.',
          form: [
            {
              component: 'Checkbox',
              key: 'cancel_sms',
              label: 'Enviar SMS de cancelación de reserva al cliente',
              model: 'cancelSMS',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'cancel_email',
              label: 'Enviar Email de cancelación de reserva al cliente',
              model: 'cancelEmail',
              icon: 'pi pi-user',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'cancel_sms_org',
              label: 'Enviar SMS de cancelación de reserva al punto de venta',
              model: 'cancelSMSOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
            {
              component: 'Checkbox',
              key: 'cancel_email_org',
              label: 'Enviar Email de cancelación de reserva al punto de venta',
              model: 'cancelEmailOrganization',
              icon: 'pi pi-desktop',
              wrapper: {
                classes: ['field-checkbox', 'form-field'],
              },
              props: {
                binary: true,
              },
            },
          ],
        },
        {
          status: 'Día de la cita',
          icon: 'pi pi-check',
          image: 'reserved_booking.jpg',
          color: '#3bb273',
          description: 'Se ha completado la reserva',
        },
      ],
    };
  },
  methods: {
    mapBooking(notificationsConfig, remindersConfig) {
      const { notificationOptions, customReminders } = parseApiConfig(
        notificationsConfig,
        remindersConfig
      );

      this.originalNotificationOptions = notificationOptions;
      this.notificationOptions = _.cloneDeep(notificationOptions);
      this.originalCustomReminders = customReminders;
      this.customReminders = _.cloneDeep(customReminders);
    },
    addReminder() {
      const defaultData = {
        key: null,
        customReminder: true,
        hoursBefore: 24,
        sms: true,
        email: true,
      };

      defaultData.key = `cr${new Date().getTime()}`;

      this.customReminders.push(defaultData);
    },
    removeReminder(reminderKey) {
      this.customReminders.splice(
        this.customReminders.findIndex(
          (reminder) => reminder.key === reminderKey
        ),
        1
      );
    },
    cancel() {
      this.customReminders = _.cloneDeep(this.originalCustomReminders);
      this.notificationOptions = _.cloneDeep(this.originalNotificationOptions);
    },
    async save() {
      this.saving = true;
      const [apiNotifications, apiReminders] = mapApiConfig(
        this.notificationOptions,
        this.customReminders
      );
      await this.organizationService.putNotificationsConfig(
        this.$route.params.id,
        apiNotifications
      );
      const newOrganization = await this.organizationService.putReminders(
        this.$route.params.id,
        apiReminders
      );
      const apiConfig = newOrganization.config.notificationConfig;
      this.notificationConfigApiData = apiConfig;
      this.mapBooking(apiConfig, newOrganization.config.reminders);
      this.saving = false;
    },
  },
  computed: {
    getBookingEvents: function () {
      const bookingEvents = _.cloneDeep(this.bookingEvents);
      const addReminderIndex = bookingEvents.findIndex(
        (event) => event.addReminder
      );
      bookingEvents.splice(addReminderIndex, 0, ...this.customReminders);
      return bookingEvents;
    },
    isUnsaved() {
      return (
        JSON.stringify(this.customReminders) !==
          JSON.stringify(this.originalCustomReminders) ||
        JSON.stringify(this.notificationOptions) !==
          JSON.stringify(this.originalNotificationOptions)
      );
    },
  },
};
</script>

<style lang="scss">
.spinner_container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.customized-timeline {
  .p-timeline-event:nth-child(even) .form-field {
    flex-direction: row-reverse;
  }

  .add-reminder {
    position: absolute;
    left: 50%;
    transform: translateX(calc(-50% + 110px));
    margin-top: -5px;
  }

  .field-checkbox > label,
  .field-radiobutton > label {
    margin-left: 0;
    display: flex;
    flex-direction: inherit;
  }

  .p-timeline-event:nth-child(even) .add-reminder {
    transform: translateX(calc(-50% - 110px));
  }

  @media screen and (max-width: 960px) {
    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;

      .p-timeline-event-content {
        text-align: left !important;
      }

      .form-field {
        flex-direction: row;
      }
    }
    .add-reminder {
      transform: translateX(calc(-50% - 110px)) !important;
      left: 260px;
      width: max-content;
    }
    .p-timeline-event-opposite {
      flex: 0;
    }
    .p-card {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
</style>
